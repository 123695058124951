.ais-SearchBox {
  margin: 1em 0;
}

.ais-Pagination {
  margin-top: 1em;
}

.left-panel {
  float: left;
  width: 250px;
  margin-right: 0.6rem;
}

.right-panel {
  margin-left: 260px;
}

.ais-InstantSearch {
  /* max-width: 960px; */
  width: 80vw;
  overflow: hidden;
  margin: 0 auto;
}

@media (max-width: 812px) {
  .ais-InstantSearch {
    margin: 0 auto;
    display: flex;
	  flex-direction: column;
  }
  .left-panel {
    width: 100%;
    margin-right: 0rem;
  }
  .right-panel {
    width: 100%!important;
    margin-left:0;
  }  
}

.ais-Hits-item {
  /* margin-bottom: .1em; */
  margin-bottom: .1em;
  margin: 0rem!important;
  /* padding: 0rem!important; */
margin-left: 1rem!important;
margin-top: 1rem!important;
padding-top: .5rem!important;
padding-bottom: .5rem!important;
padding-left: 1rem!important;
  /* padding-bottom: 0; */
  width: calc(32.9% - 1rem);
}

.ais-Hits-item img {
  margin-right: 1em;
}

.hit-name {
  margin-bottom: 0em;
}

.hit-description {
  color: #888;
  font-size: 14px;
  margin-bottom: 0em;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  margin-right: 5px;
}

.ais-RefinementList{
  padding-left: 0rem;
}

.ais-ClearRefinements-button, .ais-CurrentRefinements-reset, .ais-GeoSearch-redo, .ais-GeoSearch-reset, .ais-HierarchicalMenu-showMore, .ais-InfiniteHits-loadMore, .ais-InfiniteHits-loadPrevious, .ais-InfiniteResults-loadMore, .ais-Menu-showMore, .ais-RefinementList-showMore {
  padding: .3rem .5rem;
  font-size: .8rem;
  color: #fff;
  background-color: #343a40;
  border-radius: 5px;
  transition: background-color .2s ease-out;
  outline: none;
}

.ais-ClearRefinements-button:focus, .ais-ClearRefinements-button:hover, .ais-CurrentRefinements-reset:focus, .ais-CurrentRefinements-reset:hover, .ais-GeoSearch-redo:focus, .ais-GeoSearch-redo:hover, .ais-GeoSearch-reset:focus, .ais-GeoSearch-reset:hover, .ais-HierarchicalMenu-showMore:focus, .ais-HierarchicalMenu-showMore:hover, .ais-InfiniteHits-loadMore:focus, .ais-InfiniteHits-loadMore:hover, .ais-InfiniteHits-loadPrevious:focus, .ais-InfiniteHits-loadPrevious:hover, .ais-InfiniteResults-loadMore:focus, .ais-InfiniteResults-loadMore:hover, .ais-Menu-showMore:focus, .ais-Menu-showMore:hover, .ais-RefinementList-showMore:focus, .ais-RefinementList-showMore:hover {
  background-color: #94bae0;
}

.ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: #94bae0;
  border-color: #94bae0;
}

.ais-Highlight-highlighted, .ais-Snippet-highlighted {
  background-color: #94bae0;
  color: white;
}