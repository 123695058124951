.core-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    width: 490px;
}

.print-container{
    display: flex;
	flex-direction: column;
    width: 490px;
    height: 146px;
    align-items: center;
    /* border: 1px solid black; */
}

.print-location{
    width: 490px;
    color:#000000;
    /* text-align: center; */
    height: 26px;
    padding-top: 4px;
    padding-left: 20px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid black;
    display: flex;
}

.label-left{
    width:245px;
}

.label-left-inner{
    width:245px;
}

.label-right{
    height:86px;
    vertical-align: bottom;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 245px;
    justify-content: center;
	align-items: center;    
}

.print-location-left{
    width: 50%;
}

.print-location-right{
    text-align: center;
    width: 50%;
}

.title-container{
    display: flex;
    flex-direction: row;
    width:100%;
    margin-top: 10px;
}

.price-container{
    width: 100px;
}

.title{
    width: auto;
    padding-left: 30px;
    line-height: 16px;
    font-size: 16px;
}

.currency-symbol{
    position:relative;
    font-size: 18px;
    bottom: 8px;
    padding-left: 16px;
}

.price{
    font-size: 28px;
    line-height: 10px;
}

.cents{
    position:relative;
    font-size: 16px;
    bottom: 8px;
}

.logoprint{
    width: 35px;
}


