@import "./style/variables";
@import "./style/mixins";
@import "./style/modals";

@import "./style/typography";

// Core CSS
@import "./style/misc";
@import "./style/sidebar-and-main-panel";
@import "./style/fixed-plugin";
@import "./style/modal";

@import "./style/buttons";
@import "./style/social-buttons";
@import "./style/inputs";

@import "./style/alerts";
@import "./style/tables";

@import "./style/checkbox-radio-switch";
@import "./style/navbars";
@import "./style/footers";
@import "./style/tags";
@import "./style/tabs-navs-pagination";
@import "./style/progress-bars";
@import "./style/sliders";



// Fancy Stuff
@import "./style/dropdown";
@import "./style/cards";
@import "./style/chartist";
@import "./style/responsive";
@import "./style/bootstrap-switch";

// Nucleo Icons
@import "./style/partial-nucleo-icons";

//plugins css
@import "./style/plugins/animate";
@import "./style/plugins/sweetalert2";

@import "./style/plugins/jquery.jvectormap";
@import "./style/plugins/bootstrap-table";
@import "./style/plugins/datatables.net";
@import "./style/plugins/fullcalendar";
@import "./style/plugins/chartist";
// @import "style/plugins/perfect-scrollbar";
@import "./style/plugins/bootstrap-select";
@import "./style/plugins/datetime-picker";
@import "./style/plugins/nouislider";

// React Differences
@import "./stylepr/style";
