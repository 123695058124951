
.load-wrapp {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #007bff4d;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0px;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-wrapp p {padding: 0 0 0px;}
.load-wrapp:last-child {margin-right: 0;}

.line {
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 10px;
  background-color: #04284e;
  margin: 10px;
  border: solid 1px white;
}

.line-logo{
  background-image: url(../../../../assets/img/l9-logo-load.png);
  /* opacity: 0.75; */
  background-position: 8px;
  background-size: 18px 20px;
  background-repeat: no-repeat;
}

.load-3 .line:nth-last-child(1) {animation: loadingC .6s .1s ease-in-out infinite;}
.load-3 .line:nth-last-child(2) {animation: loadingC .6s .2s ease-in-out infinite;}
.load-3 .line:nth-last-child(3) {animation: loadingC .6s .3s ease-in-out infinite;}

@keyframes loadingC {
  0% {transform: translate(0,0);}
  50% {transform: translate(0,25px);}
  100% {transform: translate(0,0);}
}
