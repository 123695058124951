.form-control::-moz-placeholder{
   @include placeholder($dark-gray,1);
}
.form-control:-moz-placeholder{
   @include placeholder($dark-gray,1);
}
.form-control::-webkit-input-placeholder{
   @include placeholder($dark-gray,1);
}
.form-control:-ms-input-placeholder{
   @include placeholder($dark-gray,1);
}

.form-control {
    background-color: $white-bg;
    border: 1px solid $light-gray;
    border-radius: $border-radius-base;
    font-size: .875rem;
    color: #565656;
    @include input-size($padding-base-vertical, $padding-base-horizontal - 4, $height-base);
    @include box-shadow(none);

    &:focus{
           background-color: $white-bg;
           border: 1px solid $medium-dark-gray;
           @include box-shadow(none);
           outline: 0 !important;
           color: #333333;
    }

    .has-success &,
    .has-error &,
    .has-success &:focus,
    .has-error &:focus{
        border-color: $light-gray;
        @include box-shadow(none);
    }

    .has-success &:focus{
        border-color: $success-color;
        color: $success-color;
    }
    .has-error &{
        color: $danger-color;
    }
    .has-error &:focus{
        border-color: $danger-color;
    }

    & + .form-control-feedback{
        border-radius: $border-radius-large;
        font-size: $font-size-base;
        margin-top: -7px;
        position: absolute;
        right: 10px;
        top: 50%;
        vertical-align: middle;
    }

    .open &{
        border-radius: $border-radius-base $border-radius-base 0 0;
        border-bottom-color: transparent;
    }
}

.input-lg{
    height: 55px;
    padding: $padding-large-vertical $padding-large-horizontal;
}

.has-error{
    .form-control-feedback{
        color: $danger-color;
    }
}
.has-success{
    .form-control-feedback{
        color: $success-color
    }
}


.input-group-addon {
    background-color: $white-color;
    border: 1px solid $light-gray;
    border-radius: $border-radius-base;

    .has-success &,
    .has-error &{
        background-color: $white-color;
        border: 1px solid $light-gray;
    }
    .has-error .form-control:focus + &{
        border-color: $danger-color;
        color: $danger-color;
    }
    .has-success .form-control:focus + &{
        border-color: $success-color;
        color: $success-color;
    }
    .form-control:focus + &,
    .form-control:focus ~ &{
        background-color: $white-color;
        border-color: $dark-gray;
    }
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
    border-right: 0 none;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
    border-left: 0 none;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: $smoke-bg;
    color: $default-color;
    cursor: not-allowed;
}

.input-group-btn .btn{
    border-width: $border-thin;
    padding: $padding-round-vertical $padding-base-horizontal;
}
.input-group-btn .btn-default:not(.btn-fill){
    border-color: $medium-gray;
}

.input-group-btn:last-child > .btn{
    margin-left: 0;
}

.input-group-focus .input-group-addon{
    border-color: $dark-gray;
}

.star{
    color: #FB404B;
    padding-left: 3px;
}

.card .card-body {
    .control-label{
        text-align: right;
        padding-top: 18px;
    }
    .form-control-static,
    .col-sm-10 .form-check:first-of-type,
    .col-sm-4 .form-check:first-of-type{
        padding-top: 8px;
    }
}

.form-group.has-error{
    
.error {
    color: #ff0011!important;
    text-transform: none!important;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px!important;;
}
}

.form-group.has-success{
    .error{
        display: none !important;
    }
}

.PhoneInputInput {

    border: 0;
}